import React, { Component } from 'react';
import QlikConnection from '../qlikEngine/qlikConnection.js';
import QlikObject from '../qlikEngine/qlikObject.js';
import DoughNut from '../components/placeComponent.js'
import SideBar from "../components/sideBar.js";
import Pricesens from "./price_sens"
export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  onMessage(state){
    this.setState(state);
  }

  render() {

    var content, contentDrop,contentDrop2, contentButton = [];
    var contentArray = [
        {  
            qlikId:"5439fdc3-aa2c-4631-a8f2-3a3a11a2afb2",
            id:"5439fdc3-aa2c-4631-a8f2-3a3a11a2afb2",
        }
    ]
    var contentArray3 = [
        {  
            qlikId:"KCqtaVK",
            id:"sec_row_cnt_2",
            secRow:true,
            secDet:[
                {
                    num_id:1,
                    position:'col-8',
                    qlikId:'a25f9195-083d-4201-8a46-ae393ecfca44',
                    height:'100px',
                    id:"table_filter"
                },
                {
                    num_id:3,
                    position:'col-4',
                    qlikId:'6bbabebe-ce3f-4192-9514-1f53f8d36104',
                    height:'100px',
                    id:"table_filter2"
                }
            ]
        }
    ]
    var contentArray2 = [
        {  
            qlikId:"8f97f29e-058a-4bbd-b4d0-9a928d968524",
            id:"side_single_select",
            secRow:true,
            secDet:[
                {
                    num_id:1,
                    position:'col-12',
                    qlikId:'2910b41f-1c31-44b3-90c7-58ab54ada5a1',
                    height:'500px',
                    id:"side_bar_filter"
                },
                {
                    num_id:3,
                    position:'col-12',
                    qlikId:'314f794a-01a4-4ca1-9246-4d3bff05ae10',
                    height:'240px',
                    id:"bottom_comments_sec"
                }
            ]
        }
    ]
    var contentDough, contentDough2, contentDough3, contentMap, contentTab=[];
    if(this.state.app){
    content = <QlikObject qlikId="f6593044-6a8a-4a5f-8350-965a345aa8ba" chartId="helpdesk-line-chart" app={this.state.app} height="40px" minWidth="400px"/>
    contentDrop =  <QlikObject qlikId="98f479bb-d067-440b-ab05-c3e81a9b425b" chartId="helpdesk-line-chart1" app={this.state.app} height="40px" minWidth="20px"/>
    contentDrop2 =  <QlikObject qlikId="862567e6-ad1c-4b66-85aa-fea9df334d1b" chartId="helpdesk-line-chart2" app={this.state.app} height="40px" minWidth="20px"/>
    contentButton = <QlikObject qlikId="mJyRnJ" chartId="clearButton" app={this.state.app} height="35px" minWidth="50px"/>
    contentDough = <DoughNut contents={contentArray} appKey={this.state.app} height="520px" position="col-12" cards="card"/>
    contentDough2 = <DoughNut contents={contentArray2} appKey={this.state.app} height="40px" position="col-12" cards=""/>
    contentDough3 = <DoughNut contents={contentArray3} appKey={this.state.app} height="40px" position="col-12" cards=""/>
    }
    return (
      <>
        <section className="contentSection compare_period_sec">
          <div className="row pad-2 rtl">
          <div className="col-10 contentSecColumnFixed">
                <div className="card-panel with-background row" style={{justifyContent:'flex-start'}}>
                  <div class="col-2">{contentDrop}</div>
                  <div class="col-2">{contentDrop2}</div>
                  <div class="col-1">
                  {contentButton}
                  </div>
                </div>
            </div>
            <div className="col-2 side_sec_filter contentSecColumn">
                <div className="row cnt_box1">
                    {contentDough2}
                </div>
            </div>
            <div className="col-8 contentSecColumn">
                <div className="card-panel with-background cnt_table_filter_box">
                    <div className='row '>
                            {contentDough3}
                    </div>
                </div>
                <div className="card-panel with-background">
                    <div className="row cnt_box1">
                        {contentDough}
                    </div>
                </div>
            </div>
          </div>
            <QlikConnection callback={this.onMessage.bind(this)} appName="app" qvfName="de7576dd-b456-457b-9167-d18446dd86ba"/>
        </section>
        <SideBar  page="compare_period" qv_name="de7576dd-b456-457b-9167-d18446dd86ba"
            side_btn={[
                {
                    text:"גאוגרפיה וגודל אוכלוסיה",link:"/share",icon:"chart-area"
                  },
                  {
                  text:"נתח שוק ממוקד",link:"/target-share",icon:"chart-bar"
                  },
                  {
                    text:"דשבורד מכירות",link:"/dashboard-sales"
                  },
                  {
                   text:"השוואת תקופות",link:"/compare"
                  },
                  {
                   text:"נתחי שוק",link:"/share-analysis"
                  },
                  {
                   text:"salein-saleout",link:"/sales-out"
                  },
                  {
                   text:"דירוג סניפים",link:"/branch-rating"
                  },
                  {
                   text:"דוח דינמי",link:"/dynamic-report"
                  }
               ]}
          />
      </>
    );
  }
  
}
