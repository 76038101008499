import React, { Component } from 'react';
import {Navbar, NavLink, Nav} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
export default class headerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      searchBox:false,
      shareTab:false
    };
  }
  componentDidMount(){
    const loc = window.location.pathname;
    if(loc == '/share' || loc == '/dashboard-sales' || loc == '/compare' || loc == '/sales-out' || loc == '/branch-rating' || loc == '/dynamic-report' || loc == '/target-share' || loc == '/share-analysis'){
      this.setState({shareTab:true});
    }
  }
  render() {
    return(
      <Navbar fixed="top" className='nav_bar_box' collapseOnSelect expand="lg">
        <input type='text' placeholder='חיפוש' className={`search_input ${this.state.searchBox ? 'active' : ''}`} />
        <div className='search_box'>
          <span onClick={()=>this.state.searchBox ? this.setState({searchBox:false}) : this.setState({searchBox:true})}>
           <FontAwesomeIcon icon={faSearch} size='2x' />
          </span>
        </div>
        <div className='center_menus'>
            <NavLink href='/' className={window.location.pathname=='/'?'active':''}>
            שורת מצב
            </NavLink>
            <NavLink href='/dashboard-sales' className={this.state.shareTab ? 'active':''}>
            מכירות ונתחי שוק
            </NavLink>
            <NavLink href='/buyer' className={window.location.pathname=='/buyer'?'active':''}>
            אישיות הקונה
            </NavLink>
            <NavLink href='/promotions' className={window.location.pathname=='/promotions'?'active':''}>
            אפקטיביות מבצעים
            </NavLink>
            {/* <NavLink href='components.html'>
            השפעות חיצוניות
            </NavLink> */}
            <NavLink href='/price-analysis' className={window.location.pathname=='/price-analysis'|| window.location.pathname=='/price-sensitivity' ?'active':''}>
            רגישות מחיר
            </NavLink>
            {/* <NavLink href='components.html'>
            התנהגות קונים
            </NavLink>
            <NavLink href='components.html'>
            תובנות להגדלת המכר
            </NavLink> */}
        </div>
        <div>
          <a className="brand-logo" href="#/"><img alt="" src="https://ofekbot.co.il/qlik/image/logo.png"/><span className="logo-tag">Upgrading<br/>Shopping<br/>Experience</span></a>
        </div>
        
      </Navbar>
    )
  }

}