import React, { Component } from 'react';
import QlikConnection from '../qlikEngine/qlikConnection.js';
import QlikObject from '../qlikEngine/qlikObject.js';
import DoughNut from '../components/placeComponent.js'
import SideBar from "../components/sideBar.js";
export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  onMessage(state){
    this.setState(state);
  }

  componentDidMount(){
    const rateCheck = setInterval(()=>{
      var cnt = document.querySelectorAll('.sn-kpi-glyph-wrapper svg');

      if(cnt){
        cnt.forEach((item,index,arr)=>{
          var color = item.getAttribute("data-testid");
          item.parentElement.setAttribute("data-testid",color);
          if (index === arr.length - 1){ 
            clearInterval(rateCheck); 
          }
        })
      }
    },100)
  }

  render() {

    var content, contentDrop, contentButton = [];
    var contentArray = [
      {  
        qlikId:"ZHjDqcJ",
        id:"box1",
        secRow:true,
        secDet:[
          {
            num_id:1,
            position:'col-6',
            qlikId:'sPCbS',
            height:'240px',
            id:"chrt1"
          },
          {
            num_id:2,
            position:'col-6',
            qlikId:'nmrPybK',
            height:'240px',
            id:"chrt2"
          },
          {
            num_id:3,
            position:'col-12',
            qlikId:'MRJzGh',
            height:'50px',
            id:"chrt_btn"
          }
        ]
      },
      {  
        qlikId:"GynhV",
        id:"box2",
        secRow:true,
        secDet:[
          {
            num_id:1,
            position:'col-4',
            qlikId:'dRdLZz',
            height:'180px',
            id:"dRdLZz",
            qlik2Id:"bJBAjqJ",
            id2:"bJBAjqJ",
          },
          {
            num_id:2,
            position:'col-4',
            qlikId:'LUNUPpe',
            height:'220px',
            id:"LUNUPpe"
          },
          {
            num_id:3,
            position:'col-4',
            qlikId:'CJggjrG',
            height:'180px',
            id:"CJggjrG",
            qlik2Id:"TuZkfy",
            id2:"TuZkfy",
          },
          {
            num_id:4,
            position:'col-12',
            qlikId:'aJyrWpm',
            height:'50px',
            id:"aJyrWpm"
          },
        ]
      },
      {  
        qlikId:"CPgtVdC",
        id:"CPgtVdC",
        secRow:true,
        secDet:[
          {
            num_id:1,
            position:'col-6',
            qlikId:'jWTp',
            height:'240px',
            id:"jWTp"
          },
          {
            num_id:2,
            position:'col-6',
            qlikId:'fkRZvZp',
            height:'240px',
            id:"fkRZvZp"
          },
          {
            num_id:3,
            position:'col-12',
            qlikId:'zeuJqx',
            height:'50px',
            id:"zeuJqx"
          }
        ]
      },
      {  
        qlikId:"UqjqfP",
        id:"UqjqfP",
        secRow:true,
        secDet:[
          {
            num_id:1,
            position:'col-6',
            qlikId:'ZJuVyp',
            height:'240px',
            id:"ZJuVyp"
          },
          {
            num_id:2,
            position:'col-6',
            qlikId:'rHYJDUw',
            height:'240px',
            id:"rHYJDUw"
          },
          {
            num_id:3,
            position:'col-12',
            qlikId:'mbFvxTS',
            height:'50px',
            id:"mbFvxTS"
          }
        ]
      },
      {  
        qlikId:"dwYWrFw",
        id:"dwYWrFw",
        secRow:true,
        secDet:[
          {
            num_id:1,
            position:'col-12',
            qlikId:'jBJxmz',
            height:'240px',
            id:"jBJxmz"
          },
          {
            num_id:3,
            position:'col-12',
            qlikId:'cLJLNbV',
            height:'50px',
            id:"cLJLNbV"
          }
        ]
      },
      {  
        qlikId:"sMUnZ",
        id:"sMUnZ",
        secRow:true,
        secDet:[
          {
            num_id:1,
            position:'col-12',
            qlikId:'pWUJx',
            height:'240px',
            id:"pWUJx"
          },
          {
            num_id:3,
            position:'col-12',
            qlikId:'pvPRuSh',
            height:'50px',
            id:"pvPRuSh"
          }
        ]
      }
    ]
    var contentDough, contentMap, contentTab=[];
    if(this.state.app){
    content = <QlikObject qlikId="GJhNK" chartId="helpdesk-line-chart" app={this.state.app} height="40px" minWidth="400px"/>
    contentDrop =  <QlikObject qlikId="cc29977e-2d6d-42aa-a41c-f2ab7bf1e535" chartId="helpdesk-line-chart1" app={this.state.app} height="40px" minWidth="20px"/>
    contentButton = <QlikObject qlikId="MPppfzP" chartId="clearButton" app={this.state.app} height="35px" minWidth="50px"/>
    contentDough = <DoughNut contents={contentArray} appKey={this.state.app} height="40px" position="col-4" cards="card" minWidth="200px"/>
    }
    return (
      <>
        <section className="contentSection">
          <div className="row pad-2 rtl">
          <div className="col-10 contentSecColumnFixed">
                <div className="card-panel with-background row">
                  <div class="col-11">{content}</div>
                  <div class="col-1">
                  {contentButton}
                  </div>
                </div>
            </div>
            <div className="col-10 contentSecColumn dashb">
            <div className="">
            <div className="row dashboard_cnt">
                {contentDough}
                </div>
                </div>
            </div>
          </div>
            <QlikConnection callback={this.onMessage.bind(this)} appName="app" qvfName="82ccf23e-db0f-49e4-9459-b14ff3adff6d"/>
        </section>
        <SideBar dropdown_box="drejANK" page="dashboard"
        side_btn={[
          {
            text:"גאוגרפיה וגודל אוכלוסיה",link:"/share",icon:"chart-area"
          },
          {
           text:"השוואת תקופות",link:"",icon:"chart-bar"
          }
          ]}
         />
      </>
    );
  }
  
  }


