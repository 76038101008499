import React, { Component } from 'react';
import QlikObject from '../qlikEngine/qlikObject.js';
export default class doughNut extends Component {
    constructor(props){
        super(props);
        this.state = {};
      }
    
    render() {
        return(
            this.props.contents.map((content, index)=>
            <React.Fragment key={index}>
                  { content.qlikMapMarkId ?
                    <div key={content.qlikMapMarkId + index} className={this.props.position1}>
                    <div  key={content.qlikMapMarkId + index} className={this.props.cards}>
                        <QlikObject  key={content.qlikMapMarkId + index}  qlikId={content.qlikMapMarkId} chartId={content.id2} app={this.props.appKey} height={this.props.height} 
                        Width={this.props.Width1}
                        backGround={this.props.bgColor}
                        bRadius={this.props.bRadius}/> 
                    </div>
                    </div>:''
                }
                <div key={content.qlikId + index} className={this.props.position}>
                <div key={content.qlikId + index}  className={this.props.cards}>
                    <QlikObject key={content.qlikId + index} qlikId={content.qlikId} chartId={content.id} app={this.props.appKey} height={this.props.height} 
                    minWidth={this.props.minWidth}
                    backGround={this.props.bgColor}
                    bRadius={this.props.bRadius}/> 
                    {
                        content.secRow ? 
                        <div className='row'>
                            {
                            content.secDet.map((item,idx)=>{
                                return(
                                    <div key={item.qlikId + idx} className={item.position}>
                                        <QlikObject key={item.qlikId + idx} qlikId={item.qlikId} chartId={item.id} app={this.props.appKey} height={item.height}  />
                                        { item.qlik2Id ?
                                            <div key={item.qlik2Id + idx}>
                                            <QlikObject key={item.qlik2Id + idx} qlikId={item.qlik2Id} chartId={item.id2} app={this.props.appKey} height={item.height2 ? `${item.height2}` : '40px'} /></div>: ''
                                        }
                                    </div>
                                )
                            })
                           }
                        </div>:''
                    }
                </div>
                { content.qlik2Id ?
                   <div key={content.qlik2Id + index}>
                   <QlikObject key={content.qlik2Id + index} qlikId={content.qlik2Id} chartId={content.id2} app={this.props.appKey} height="40px" minWidth={this.props.minWidth}/></div>: ''
                    }
                </div>
               
                </React.Fragment>
            )
        )
    }
}
