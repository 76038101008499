import React, { Component } from 'react';
import QlikConnection from '../qlikEngine/qlikConnection.js';
import QlikObject from '../qlikEngine/qlikObject.js';
import DoughNut from '../components/placeComponent.js'
import SideBar from "../components/sideBar.js";
import Pricesens from "./price_sens"
export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  onMessage(state){
    this.setState(state);
  }

  render() {

    var content, contentDrop,contentDrop2, contentButton = [];
    var contentArray = [
        {  
            qlikId:"KCqtaVK",
            id:"sec_row_cnt_2",
            secRow:true,
            secDet:[
                {
                    num_id:1,
                    position:'col-8',
                    qlikId:'auuAcB',
                    height:'520px',
                    id:"auuAcB"
                },
                {
                    num_id:1,
                    position:'col-4',
                    qlikId:'XkgsWLS',
                    height:'520px',
                    id:"XkgsWLS"
                },
            ]
        }
    ]
    var contentArray2 = [
        {  
            qlikId:"5402df99-4d79-4b52-8cd9-0c3020847c51",
            id:"side_single_select",
            secRow:true,
            secDet:[
                {
                    num_id:1,
                    position:'col-12',
                    qlikId:'b473700b-810f-4b97-8506-44f5bf8d0a93',
                    height:'500px',
                    id:"side_bar_filter"
                },
                {
                    num_id:3,
                    position:'col-12',
                    qlikId:'4aefe476-bd26-4a13-9c46-004bb9872675',
                    height:'240px',
                    id:"bottom_comments_sec"
                }
            ]
        }
    ]
    var contentDough, contentDough2, contentDough3, contentMap, contentTab=[];
    if(this.state.app){
    content = <QlikObject qlikId="5616e82e-b21a-4235-a4b7-beed0d8d08f2" chartId="helpdesk-line-chart" app={this.state.app} height="40px" minWidth="400px"/>
    contentButton = <QlikObject qlikId="3a318a1e-cc1b-4222-837c-2b53a3a7e86e" chartId="clearButton" app={this.state.app} height="35px" minWidth="50px"/>
    contentDough = <DoughNut contents={contentArray} appKey={this.state.app} height="520px" position="col-12" cards="card"/>
    contentDough2 = <DoughNut contents={contentArray2} appKey={this.state.app} height="40px" position="col-12" cards=""/>
    }
    return (
      <>
        <section className="contentSection salesout_sec">
          <div className="row pad-2 rtl">
          <div className="col-10 contentSecColumnFixed">
                <div className="card-panel with-background row" >
                  <div class="col-10">{content}</div>
                  <div class="col-2">
                  {contentButton}
                  </div>
                </div>
            </div>
            <div className="col-2 side_sec_filter contentSecColumn">
                <div className="row cnt_box1">
                    {contentDough2}
                </div>
            </div>
            <div className="col-8 contentSecColumn">
                <div className="card-panel with-background">
                    <div className="row cnt_box1">
                        {contentDough}
                    </div>
                </div>
            </div>
          </div>
            <QlikConnection callback={this.onMessage.bind(this)} appName="app" qvfName="de7576dd-b456-457b-9167-d18446dd86ba"/>
        </section>
        <SideBar  page="compare_period" qv_name="de7576dd-b456-457b-9167-d18446dd86ba"
            side_btn={[
              {
                text:"גאוגרפיה וגודל אוכלוסיה",link:"/share",icon:"chart-area"
              },
              {
              text:"נתח שוק ממוקד",link:"/target-share",icon:"chart-bar"
              },
              {
                text:"דשבורד מכירות",link:"/dashboard-sales"
              },
              {
               text:"השוואת תקופות",link:"/compare"
              },
              {
               text:"נתחי שוק",link:"/share-analysis"
              },
              {
               text:"salein-saleout",link:"/sales-out"
              },
              {
               text:"דירוג סניפים",link:"/branch-rating"
              },
              {
               text:"דוח דינמי",link:"/dynamic-report"
              }
               ]}
          />
      </>
    );
  }
  
}
