import ReactDOM from "react-dom";
import {useState} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Buyer from "./sheets/buyer_personality";
import Dashboard from "./sheets/dashboard";
import Header from "./components/headerComponent.js";
import MarketShare from "./sheets/market_share"
import PriceSensitivity from "./sheets/price_sens"
import PriceAnalyz from "./sheets/price_analyz"
import Promotions from "./sheets/promotions"
import DashboardSales from "./sheets/dashboard_sales"
import ComparePeriod from "./sheets/compare_period"
import SalesOut from "./sheets/salesout"
import BranchRating from "./sheets/branch_rating"
import DynamicReport from "./sheets/dynamic_report"
import TargetShare from "./sheets/target_share"
import ShareAnalyz from "./sheets/share_analyz"
import Loading from "./components/loading";
export default function App() {
  const [loading,setLoading] = useState(true);
 const CheckLoad = setInterval(()=>{
    var load = document.querySelector('body').classList.contains('loaded');
    if(load){
      setTimeout(()=>{
        setLoading(false);
      },1000)
      clearInterval(CheckLoad);
    }
  },100)
  return (
    <div className="App">
    <header className="App-header">
     <Header/>
   </header>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Dashboard />}/>
        <Route path='/buyer' element={<Buyer />}/>
        <Route path='/share' element={<MarketShare />}/>
        <Route path='/price-sensitivity' element={<PriceSensitivity />}/>
        <Route path='/price-analysis' element={<PriceAnalyz />}/>
        <Route path='/promotions' element={<Promotions />}/>
        <Route path='/dashboard-sales' element={<DashboardSales />}/>
        <Route path='/compare' element={<ComparePeriod />}/>
        <Route path='/sales-out' element={<SalesOut />}/>
        <Route path='/branch-rating' element={<BranchRating />}/>
        <Route path='/dynamic-report' element={<DynamicReport />}/>
        <Route path='/target-share' element={<TargetShare />}/>
        <Route path='/share-analysis' element={<ShareAnalyz />}/>
      </Routes>
    </BrowserRouter>  
    {
      loading ?
      <Loading /> : null
    } 
  </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));