import React, { Component } from 'react';

export default class QlikConnection extends Component {

    constructor(props){
      super(props);
      this.state = {};
    }
  
    componentDidMount(){
        this.login();
    }
  
    async openQlikConnection(){
      var me = this;
      var config ={
        host: 'drill.eu.qlikcloud.com', 
        prefix: '/',
        port: 443,
        isSecure: true,
        webIntegrationId: '7BHQp86uGOcovkHdAJ4mhzuBKZqxexwR'
    };
    const requireJs = window.requirejs;
    requireJs.config( {
        baseUrl: ( config.isSecure ? "https://" : "http://" ) + config.host + (config.port ? ":" + config.port : "") + config.prefix + "resources",
        webIntegrationId: config.webIntegrationId
    } );	
    requireJs( ["js/qlik"], function ( qlik ) {
      qlik.setOnError( function ( error ) {
        me.props.callback({errorMessage: 'Error upon loading QVF from Qlik ' + error, app: null});
        console.log(error);
      } );
      //open apps — inserted here —
      var app = qlik.openApp( me.props.qvfName, config );
      var state={ errorMessage: null};
      state[me.props.appName] = app;
      console.log(state)
      me.props.callback(state);
    } );
  
    }

    login() {
      function isLoggedIn() {
          return fetch("https://drill.eu.qlikcloud.com/api/v1/users/me", {
              method: 'GET',
              mode: 'cors',
              credentials: 'include',
              headers: {
                  'Content-Type': 'application/json',
                  'qlik-web-integration-id': '7BHQp86uGOcovkHdAJ4mhzuBKZqxexwR',
              },
          }).then(response => {
              return response.status === 200;
          });
      }
      return isLoggedIn().then(loggedIn =>{
          if (!loggedIn) {
              window.location.href = "https://drill.eu.qlikcloud.com/login?qlik-web-integration-id=7BHQp86uGOcovkHdAJ4mhzuBKZqxexwR&returnto=" + window.location.href;
              throw new Error('not logged in');
          } else {
            this.openQlikConnection()
          }
      });
    }
  
    render() {
      return <span/>;
    }
  }