import React, { Component } from 'react';
import QlikConnection from '../qlikEngine/qlikConnection.js';
import QlikObject from '../qlikEngine/qlikObject.js';
import DoughNut from '../components/placeComponent.js'
import SideBar from "../components/sideBar.js";
export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  onMessage(state){
    this.setState(state);
  }

  render() {

    var content, contentDrop, contentButton = [];
    var contentArray = [
      {  
        qlikId:"KCqtaVK",
        id:"sec_row_cnt_2",
        secRow:true,
          secDet:[
            {
                num_id:1,
                position:'col-9',
                qlikId:'YEhaZk',
                height:'240px',
                id:"YEhaZk"
            },
            {
                num_id:3,
                position:'col-3',
                qlikId:'ARcupCF',
                height:'240px',
                id:"ARcupCF"
            },
            {
                num_id:3,
                position:'col-3',
                qlikId:'fjBPL',
                height:'240px',
                id:"fjBPL"
            }
        ]
      }
    ]
    var contentArray2 = [
        {  
          qlikId:"WPJPJB",
          id:"WPJPJB",
          secRow:true,
            secDet:[
              {
                  num_id:1,
                  position:'equal_wrap',
                  qlikId:'NSeNc',
                  height:'80px',
                  id:"NSeNc",
                  qlik2Id:"JSmjd",
                  id2:"JSmjd",
                  height2:"80px"
              },
              {
                  num_id:3,
                  position:'equal_wrap',
                  qlikId:'UBgmh',
                  height:'200px',
                  id:"UBgmh"
              },
              {
                  num_id:3,
                  position:'equal_wrap',
                  qlikId:'TqAyU',
                  height:'200px',
                  id:"TqAyU"
              },
              {
                num_id:3,
                position:'equal_wrap',
                qlikId:'JpBSCVr',
                height:'200px',
                id:"JpBSCVr"
            },
            {
                num_id:3,
                position:'equal_wrap',
                qlikId:'mMmX',
                height:'200px',
                id:"mMmX"
            }
          ]
        }
      ]
    var contentDough, contentDough2, contentMap, contentTab=[];
    if(this.state.app){
    content = <QlikObject qlikId="ebe022ca-5300-4fec-bd5a-d62339b83454" chartId="helpdesk-line-chart" app={this.state.app} height="40px" minWidth="400px"/>
    contentDrop =  <QlikObject qlikId="cc29977e-2d6d-42aa-a41c-f2ab7bf1e535" chartId="helpdesk-line-chart1" app={this.state.app} height="40px" minWidth="20px"/>
    contentButton = <QlikObject qlikId="gkehEH" chartId="clearButton" app={this.state.app} height="35px" minWidth="50px"/>
    contentDough = <DoughNut contents={contentArray} appKey={this.state.app} height="40px" position="col-12" cards="card"/>
    contentDough2 = <DoughNut contents={contentArray2} appKey={this.state.app} height="40px" position="col-12" cards="card"/>
    }
    return (
      <>
        <section className="contentSection sensitivity_sec">
          <div className="row pad-2 rtl">
          <div className="col-10 contentSecColumnFixed">
                <div className="card-panel with-background row">
                  <div class="col-11">{content}</div>
                  <div class="col-1">
                  {contentButton}
                  </div>
                </div>
            </div>
            <div className="col-10 contentSecColumn">
            <div className="card-panel with-background">
                <div className="row cnt_box1">
                    {contentDough}
                </div>
            </div>
            <div className="card-panel with-background">
                <div className="row cnt_box2">
                    {contentDough2}
                </div>
            </div>
            </div>
          </div>
            <QlikConnection callback={this.onMessage.bind(this)} appName="app" qvfName="82ccf23e-db0f-49e4-9459-b14ff3adff6d"/>
        </section>
        <SideBar dropdown_box="c8fed612-21bd-4a8b-be63-5c1db480ba02" page="price_sensitivity"
            side_btn={[
              {
                text:"ניתוח מחיר לפי תקופות",link:"/price-analysis"
              },
              {
              text:"מחשבון רגישות מחיר",link:"/price-sensitivity"
              }
            ]}
          />
      </>
    );
  }
  
  }


