import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faBell, faCalendar} from '@fortawesome/free-regular-svg-icons';
import QlikConnection from '../qlikEngine/qlikConnection.js';
import QlikObject from '../qlikEngine/qlikObject.js';
import { faUserCircle, faChartArea, faChartBar } from '@fortawesome/free-solid-svg-icons';
library.add(faChartArea, faChartBar);
export default class sideBar extends Component {
  constructor(props){
    super(props);
    this.state = {
      user_name:'שם הספק',
      email:'orly@drill.com',
      analysis_text:'אנליזה אחרונה',
      date:'28 נוב 2021',
      menus:[
        {
          id:1,
          title:'גאוגרפיה וגודל אוכלוסיה',
          icon:'chart-area',
          link:"/share"
        },
        {
          id:2,
          title:'נטיות קונה',
          icon:'chart-bar',
          link:""
        }
      ],
      activeTab:0
    };
  }
  onMessage(state){
    this.setState(state);
  }
  render() {
    const {dropdown_box,side_btn,page,qv_name} = this.props;
    var contentDrop = [];
    if(this.state.app){
      contentDrop =  <QlikObject qlikId={dropdown_box} chartId="helpdesk-line-chart5" app={this.state.app} height="40px" minWidth="20px"/>
    }
    return(

    <div className="sidenav sidenav-fixed right">
      <div className="sidebar_box" >
        <div className='avatar'>
          <FontAwesomeIcon icon={faUserCircle} size='3x' />
        </div>
        <h4 className='user_name'>{this.state.user_name}</h4>
        <p className='email'>{this.state.email}</p>
        <div className='icons_box'>
          {contentDrop}
        </div>
        <div className={`menu_items ${page}`}>
          {
            side_btn?
            side_btn.map((item,index)=>{
              return(
                <a key={index} href={item.link} className={window.location.pathname == item.link ? 'active' : ''} onClick={()=>{this.setState({activeTab:index});this.props.func(index)}}>
                  <span></span>
                  {
                    item.icon ? 
                    <FontAwesomeIcon icon={item.icon} size='2x' /> : null
                  }
                  {item.text}
                  </a>
              )
            }) : null
          }
        </div>
       </div>
       <QlikConnection callback={this.onMessage.bind(this)} appName="app" qvfName={qv_name ? qv_name : '82ccf23e-db0f-49e4-9459-b14ff3adff6d'}/>
    </div>
    )
  }

}