import React, { Component } from 'react';
import QlikConnection from '../qlikEngine/qlikConnection.js';
import QlikObject from '../qlikEngine/qlikObject.js';
import DoughNut from '../components/placeComponent.js'
import SideBar from "../components/sideBar.js";
export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  onMessage(state){
    this.setState(state);
  }

  componentDidMount(){
    const rateCheck = setInterval(()=>{
      var cnt = document.querySelectorAll('.sn-kpi-glyph-wrapper svg');

      if(cnt){
        cnt.forEach((item,index,arr)=>{
          var color = item.getAttribute("data-testid");
          item.parentElement.setAttribute("data-testid",color);
          if (index === arr.length - 1){ 
            clearInterval(rateCheck); 
          }
        })
      }
    },100)
  }

  render() {

    var content, contentDrop, contentButton = [];
    var contentArray = [
      {  
        qlikId:"KCqtaVK",
        id:"sec_row_cnt_2",
        secRow:true,
          secDet:[
            {
                num_id:1,
                position:'col-6',
                qlikId:'jUMNXr',
                height:'280px',
                id:"jUMNXr",
                qlik2Id:"gzPYPmj",
                id2:"gzPYPmj",
                height2:"40px"
            },
            {
                num_id:3,
                position:'col-6',
                qlikId:'DePWa',
                height:'320px',
                id:"DePWa"
            }
        ]
      }
    ]
    var contentArray2 = [
        {  
          qlikId:"KCqtaVK",
          id:"sec_row_cnt_2",
          secRow:true,
            secDet:[
              {
                  num_id:3,
                  position:'col-6',
                  qlikId:'jFhtNS',
                  height:'440px',
                  id:"jFhtNS"
              },
              {
                  num_id:3,
                  position:'col-6',
                  qlikId:'JJsRB',
                  height:'440px',
                  id:"JJsRB"
              }
          ]
        }
      ]
    var contentDough, contentDough2, contentMap, contentTab=[];
    if(this.state.app){
    content = <QlikObject qlikId="a8315b0c-fdf2-46a6-9ed2-5bed8ccfd055" chartId="helpdesk-line-chart" app={this.state.app} height="40px" minWidth="400px"/>
    contentDrop =  <QlikObject qlikId="cc29977e-2d6d-42aa-a41c-f2ab7bf1e535" chartId="helpdesk-line-chart1" app={this.state.app} height="40px" minWidth="20px"/>
    contentButton = <QlikObject qlikId="YSkPDP" chartId="clearButton" app={this.state.app} height="35px" minWidth="50px"/>
    contentDough = <DoughNut contents={contentArray} appKey={this.state.app} height="40px" position="col-12" cards="card"/>
    contentDough2 = <DoughNut contents={contentArray2} appKey={this.state.app} height="40px" position="col-12" cards="card"/>
    }
    return (
      <>
        <section className="contentSection target_share_Sec">
          <div className="row pad-2 rtl">
          <div className="col-10 contentSecColumnFixed">
                <div className="card-panel with-background row">
                  <div class="col-11">{content}</div>
                  <div class="col-1">
                  {contentButton}
                  </div>
                </div>
            </div>
            <div className="col-10 contentSecColumn share_box">
            <div className="card-panel with-background item1">
                <div className="row cnt_box1">
                    {contentDough}
                </div>
            </div>
            <div className="card-panel with-background item2">
                <div className="row cnt_box2">
                    {contentDough2}
                </div>
            </div>
            </div>
          </div>
            <QlikConnection callback={this.onMessage.bind(this)} appName="app" qvfName="82ccf23e-db0f-49e4-9459-b14ff3adff6d"/>
        </section>
        <SideBar dropdown_box="cdc7f132-ec1f-4786-83d9-519bcd07fcba" page="target_share"
            side_btn={[
                {
                    text:"גאוגרפיה וגודל אוכלוסיה",link:"/share",icon:"chart-area"
                  },
                  {
                  text:"נתח שוק ממוקד",link:"/target-share",icon:"chart-bar"
                  },
                  {
                    text:"דשבורד מכירות",link:"/dashboard-sales"
                  },
                  {
                   text:"השוואת תקופות",link:"/compare"
                  },
                  {
                   text:"נתחי שוק",link:"/share-analysis"
                  },
                  {
                   text:"salein-saleout",link:"/sales-out"
                  },
                  {
                   text:"דירוג סניפים",link:"/branch-rating"
                  },
                  {
                   text:"דוח דינמי",link:"/dynamic-report"
                  }
            ]}
          />
      </>
    );
  }
  
  }


