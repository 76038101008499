import React, { Component } from 'react';
import QlikConnection from '../qlikEngine/qlikConnection.js';
import QlikObject from '../qlikEngine/qlikObject.js';
import DoughNut from '../components/placeComponent.js'
import SideBar from "../components/sideBar.js";
import Pricesens from "./price_sens"
export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  onMessage(state){
    this.setState(state);
  }

  render() {

    var content, contentDrop, contentButton = [];
    var contentArray = [
        {  
            qlikId:"CkakXtc",
            id:"CkakXtc",
        },
        {  
            qlikId:"39d4083a-3c95-47d6-88ae-cb73a92515cc",
            id:"39d4083a-3c95-47d6-88ae-cb73a92515cc",
        }
    ]
    var contentArray2 = [
        {  
            qlikId:"4b23db80-9c93-470b-826e-e4e48839bc2b",
            id:"side_single_select",
            secRow:true,
            secDet:[
                {
                    num_id:1,
                    position:'col-12',
                    qlikId:'c4927585-2e0a-4648-8830-7039e30fc803',
                    height:'500px',
                    id:"side_bar_filter"
                },
                {
                    num_id:3,
                    position:'col-12',
                    qlikId:'qvJsDBQ',
                    height:'120px',
                    id:"time_text_sec"
                },
                {
                    num_id:3,
                    position:'col-12',
                    qlikId:'e41fae36-7828-4c84-b33e-5282534bb13c',
                    height:'240px',
                    id:"bottom_comments_sec"
                }
            ]
        }
    ]
    var contentArray3 = [
        {  
            qlikId:"KCqtaVK",
            id:"sec_row_cnt_2",
            secRow:true,
            secDet:[
                {
                    num_id:1,
                    position:'col-12',
                    qlikId:'MSuUU',
                    height:'40px',
                    id:"MSuUU"
                },
                // {
                //     num_id:3,
                //     position:'col-4',
                //     qlikId:'cGHYP',
                //     height:'180px',
                //     id:"cGHYP",
                //     qlik2Id:"pNkVq",
                //     id2:"pNkVq",
                //     height2:"180px"
                // },
                {
                    num_id:3,
                    position:'col-8',
                    qlikId:'766c5e72-a9a4-4971-9db7-1e52ec2c2a51',
                    height:'260px',
                    id:"766c5e72-a9a4-4971-9db7-1e52ec2c2a51"
                }
            ]
        }
    ]
    var contentDough, contentDough2,contentDough3, contentMap, contentTab=[];
    if(this.state.app){
    content = <QlikObject qlikId="f6593044-6a8a-4a5f-8350-965a345aa8ba" chartId="helpdesk-line-chart" app={this.state.app} height="40px" minWidth="400px"/>
    contentDrop =  <QlikObject qlikId="cc29977e-2d6d-42aa-a41c-f2ab7bf1e535" chartId="helpdesk-line-chart1" app={this.state.app} height="40px" minWidth="20px"/>
    contentButton = <QlikObject qlikId="LnVC" chartId="clearButton" app={this.state.app} height="35px" minWidth="50px"/>
    contentDough = <DoughNut contents={contentArray} appKey={this.state.app} height="500px" position="col-6" cards="card"/>
    contentDough2 = <DoughNut contents={contentArray2} appKey={this.state.app} height="40px" position="col-12" cards=""/>
    contentDough3 = <DoughNut contents={contentArray3} appKey={this.state.app} height="40px" position="col-12" cards=""/>
    }
    return (
      <>
        <section className="contentSection dashboard_sales_sec">
          <div className="row pad-2 rtl">
          <div className="col-10 contentSecColumnFixed">
                <div className="card-panel with-background row">
                  <div class="col-10">{content}</div>
                  <div class="col-2">
                  {contentButton}
                  </div>
                </div>
            </div>
            <div className="col-2 side_sec_filter contentSecColumn">
                <div className="row cnt_box1">
                    {contentDough2}
                </div>
            </div>
            <div className="col-8 contentSecColumn">
                <div className="card-panel with-background">
                    <div className="row cnt_box1">
                        {contentDough3}
                    </div>
                </div>
            </div>
            <div className="col-8 contentSecColumn">
                <div className="card-panel with-background">
                    <div className="row cnt_box1">
                        {contentDough}
                    </div>
                </div>
            </div>
          </div>
            <QlikConnection callback={this.onMessage.bind(this)} appName="app" qvfName="de7576dd-b456-457b-9167-d18446dd86ba"/>
        </section>
        <SideBar  page="dashboard_analysis" qv_name="de7576dd-b456-457b-9167-d18446dd86ba"
            side_btn={[
                {
                    text:"גאוגרפיה וגודל אוכלוסיה",link:"/share",icon:"chart-area"
                  },
                  {
                  text:"נתח שוק ממוקד",link:"/target-share",icon:"chart-bar"
                  },
                  {
                    text:"דשבורד מכירות",link:"/dashboard-sales"
                  },
                  {
                   text:"השוואת תקופות",link:"/compare"
                  },
                  {
                   text:"נתחי שוק",link:"/share-analysis"
                  },
                  {
                   text:"salein-saleout",link:"/sales-out"
                  },
                  {
                   text:"דירוג סניפים",link:"/branch-rating"
                  },
                  {
                   text:"דוח דינמי",link:"/dynamic-report"
                  }
               ]}
          />
      </>
    );
  }
  
}
