import React, { Component } from 'react';
import QlikConnection from '../qlikEngine/qlikConnection.js';
import QlikObject from '../qlikEngine/qlikObject.js';
import DoughNut from '../components/placeComponent.js'
import SideBar from "../components/sideBar.js";
export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  onMessage(state){
    this.setState(state);
  }

  componentDidMount(){
    const rateCheck = setInterval(()=>{
      var cnt = document.querySelector('#RkAYaj_content .sn-kpi-value');
      if(cnt){
        var color = cnt.style.color;
        cnt.setAttribute("data-color",color);
        clearInterval(rateCheck);
      }
      var cnt2 = document.querySelector('#XTVGG_content .sn-kpi-value');
      if(cnt2){
        var color2 = cnt2.style.color;
        cnt2.setAttribute("data-color",color2);
        clearInterval(rateCheck);
      }
    },100)
  }

  render() {

    var content, contentDrop, contentButton = [];
    var contentArray = [
      {  
        qlikId:"KujNvT",
        id:"KujNvT",
        secRow:true,
        secDet:[
          {
            num_id:1,
            position:'col-6',
            qlikId:'YyJWh',
            height:'200px',
            id:"YyJWh"
          },
          {
            num_id:3,
            position:'col-6',
            qlikId:'XTVGG',
            height:'200px',
            id:"XTVGG"
          }
        ]
      },
      {  
        qlikId:"KCqtaVK",
        id:"KCqtaVK",
        secRow:true,
        secDet:[
          {
            num_id:1,
            position:'col-12',
            qlikId:'HyBaQP',
            height:'230px',
            id:"HyBaQP"
          }
        ]
      },
      {  
        qlikId:"KCqtaVK",
        id:"sec_row_cnt_1",
        secRow:true,
        secDet:[
          {
            num_id:1,
            position:'col-6',
            qlikId:'jPtfAz',
            height:'200px',
            id:"jPtfAz"
          },
          {
            num_id:3,
            position:'col-6',
            qlikId:'RkAYaj',
            height:'200px',
            id:"RkAYaj"
          }
        ]
      },
      {  
        qlikId:"KCqtaVK",
        id:"sec_row_cnt_2",
        secRow:true,
        secDet:[
          {
            num_id:1,
            position:'col-12',
            qlikId:'pvtmAEB',
            height:'230px',
            id:"pvtmAEB"
          }
        ]
      }
    ]
    var tabContent = [
        {  
          qlikId:"BCvjmzP",
          id:"share_table_box1",
        }
      ]
    var contentDough, contentMap, contentTab=[];
    if(this.state.app){
    content = <QlikObject qlikId="GJhNK" chartId="helpdesk-line-chart" app={this.state.app} height="40px" minWidth="400px"/>
    contentDrop =  <QlikObject qlikId="cc29977e-2d6d-42aa-a41c-f2ab7bf1e535" chartId="helpdesk-line-chart1" app={this.state.app} height="40px" minWidth="20px"/>
    contentButton = <QlikObject qlikId="MPppfzP" chartId="clearButton" app={this.state.app} height="35px" minWidth="50px"/>
    contentDough = <DoughNut contents={contentArray} appKey={this.state.app} height="40px" position="col-6" cards="card" minWidth="200px"/>
    contentTab = <DoughNut contents={tabContent} appKey={this.state.app} height="100%" position="col-12" cards="card-panel with-background h-500" minWidth="400px" bgColor="white" bRadius="5px"/>
    
    }
    return (
      <>
        <section className="contentSection">
          <div className="row pad-2 rtl">
          <div className="col-10 contentSecColumnFixed">
                <div className="card-panel with-background row">
                  <div class="col-11">{content}</div>
                  <div class="col-1">
                  {contentButton}
                  </div>
                </div>
            </div>
            <div className="col-10 contentSecColumn">
            <div className="card-panel with-background">
                <div className="row share_cnt">
                    {contentDough}
                </div>
            </div>
            <div className="chrt_table">
                <div className="row">
                    {contentTab}
                </div>
            </div>
            </div>
          </div>
            <QlikConnection callback={this.onMessage.bind(this)} appName="app" qvfName="82ccf23e-db0f-49e4-9459-b14ff3adff6d"/>
        </section>
        <SideBar dropdown_box="852db89d-564e-42df-8a8f-27b005f24a90" page="share"
         side_btn={[
          {
            text:"גאוגרפיה וגודל אוכלוסיה",link:"/share",icon:"chart-area"
          },
          {
          text:"נתח שוק ממוקד",link:"/target-share",icon:"chart-bar"
          },
          {
            text:"דשבורד מכירות",link:"/dashboard-sales"
          },
          {
           text:"השוואת תקופות",link:"/compare"
          },
          {
           text:"נתחי שוק",link:"/share-analysis"
          },
          {
           text:"salein-saleout",link:"/sales-out"
          },
          {
           text:"דירוג סניפים",link:"/branch-rating"
          },
          {
           text:"דוח דינמי",link:"/dynamic-report"
          }
          ]}
          />
      </>
    );
  }
  
  }


