import React, { Component } from 'react';
import QlikConnection from '../qlikEngine/qlikConnection.js';
import QlikObject from '../qlikEngine/qlikObject.js';
import DoughNut from '../components/placeComponent.js'
import SideBar from "../components/sideBar.js";
export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  onMessage(state){
    this.setState(state);
  }

  render() {

    var content, contentDrop, contentButton = [];
    var contentArray = [
      {  
        qlikId:"KCqtaVK",
        id:"sec_row_cnt_2",
        secRow:true,
          secDet:[
            {
                num_id:1,
                position:'col-12',
                qlikId:'zPF',
                height:'380px',
                id:"zPF"
            }
        ]
      },
      {  
        qlikId:"KCqtaVK",
        id:"sec_row_cnt_2",
        secRow:true,
          secDet:[
            {
              num_id:3,
              position:'col-12',
              qlikId:'YwZmmKf',
              height:'340px',
              id:"YwZmmKf"
           }
        ]
      }
    ]
    var contentArray2 = [
        {  
          qlikId:"epvNS",
          id:"epvNS",
        },
        {  
          qlikId:"Pzha",
          id:"Pzha",
        },
        {  
          qlikId:"vgsLCG",
          id:"vgsLCG",
        },
        {  
          qlikId:"hFkZd",
          id:"hFkZd",
        }
      ]
      var contentArray3 = [
        {  
          qlikId:"zmjfZ",
          id:"zmjfZ",
        }
      ]
    var contentDough, contentDough2, contentDough3, contentMap, contentTab=[];
    if(this.state.app){
    content = <QlikObject qlikId="7b8f00c3-a420-4c83-a7bd-5703dc0f1cb2" chartId="helpdesk-line-chart" app={this.state.app} height="40px" minWidth="400px"/>
    contentDrop =  <QlikObject qlikId="cc29977e-2d6d-42aa-a41c-f2ab7bf1e535" chartId="helpdesk-line-chart1" app={this.state.app} height="40px" minWidth="20px"/>
    contentButton = <QlikObject qlikId="NnYQH" chartId="clearButton" app={this.state.app} height="35px" minWidth="50px"/>
    contentDough = <DoughNut contents={contentArray} appKey={this.state.app} height="40px" position="col-6" cards="card"/>
    contentDough2 = <DoughNut contents={contentArray2} appKey={this.state.app} height="60px" position="col-4" cards=""/>
    contentDough3 = <DoughNut contents={contentArray3} appKey={this.state.app} height="500px" position="col-12" cards=""/>
    }
    return (
      <>
        <section className="contentSection promotions">
          <div className="row pad-2 rtl">
          <div className="col-10 contentSecColumnFixed">
                <div className="card-panel with-background row">
                  <div class="col-11">{content}</div>
                  <div class="col-1">
                  {contentButton}
                  </div>
                </div>
            </div>
            <div className="col-10 contentSecColumn">
            <div className="">
                <div className="row prom_box1">
                    {contentDough}
                </div>
            </div>
            <div className='promotion_select_box'>
              <div className="card-panel with-background">
                  <div className="row prom_box2">
                      {contentDough2}
                  </div>
              </div>
            </div>
            <div className='chart_box_cnt'>
              <div className="card-panel with-background">
                    <div className="row prom_box3">
                        {contentDough3}
                    </div>
              </div>
            </div>
            </div>
          </div>
            <QlikConnection callback={this.onMessage.bind(this)} appName="app" qvfName="82ccf23e-db0f-49e4-9459-b14ff3adff6d"/>
        </section>
        <SideBar dropdown_box="95f19bce-3b07-441b-928a-f796fdce3d68" page="promotions"
        side_btn={[
          {
            text:"גאוגרפיה וגודל אוכלוסיה",link:"/share",icon:"chart-area"
          },
          {
           text:"השוואת תקופות",link:"",icon:"chart-bar"
          }
          ]}
        />
      </>
    );
  }
  
  }


