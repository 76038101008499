import React, { Component } from 'react';
import QlikConnection from '../qlikEngine/qlikConnection.js';
import QlikObject from '../qlikEngine/qlikObject.js';
import DoughNut from '../components/placeComponent.js'
import SideBar from "../components/sideBar.js";
import Pricesens from "./price_sens"
export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  onMessage(state){
    this.setState(state);
  }

  render() {

    var content, contentDrop,contentDrop2, contentButton = [];
    var contentArray = [
        {  
            qlikId:"SMJjnk",
            id:"SMJjnk",
        }
    ]
    var contentArray3 = [
        {  
            qlikId:"mneyRdS",
            id:"mneyRdS",
        }
    ]
    var contentArray2 = [
        {  
            qlikId:"1b223416-d88b-4046-bb76-0455cc16c182",
            id:"side_single_select",
            secRow:true,
            secDet:[
                {
                    num_id:1,
                    position:'col-12',
                    qlikId:'89b2caf1-2403-4e68-b9a3-4b9bb37cea3d',
                    height:'500px',
                    id:"side_bar_filter"
                },
                {
                    num_id:3,
                    position:'col-12',
                    qlikId:'90f8b925-624f-4640-bae4-8291206acb86',
                    height:'240px',
                    id:"bottom_comments_sec"
                }
            ]
        }
    ]
    var contentDough, contentDough2, contentDough3, contentMap, contentTab=[];
    if(this.state.app){
    content = <QlikObject qlikId="d198f164-4768-4bed-8b4c-9f0fc3173289" chartId="helpdesk-line-chart" app={this.state.app} height="40px" minWidth="400px"/>
    contentButton = <QlikObject qlikId="866d937e-e56f-48bf-8ce1-5be093b729b4" chartId="clearButton" app={this.state.app} height="35px" minWidth="50px"/>
    contentDough = <DoughNut contents={contentArray} appKey={this.state.app} height="520px" position="col-12" cards="card"/>
    contentDough2 = <DoughNut contents={contentArray2} appKey={this.state.app} height="40px" position="col-12" cards=""/>
    contentDough3 = <DoughNut contents={contentArray3} appKey={this.state.app} height="60px" position="col-12" cards=""/>
    }
    return (
      <>
        <section className="contentSection salesout_sec">
          <div className="row pad-2 rtl">
          <div className="col-10 contentSecColumnFixed">
                <div className="card-panel with-background row" >
                  <div class="col-10">{content}</div>
                  <div class="col-2">
                  {contentButton}
                  </div>
                </div>
            </div>
            <div className="col-2 side_sec_filter contentSecColumn">
                <div className="row cnt_box1">
                    {contentDough2}
                </div>
            </div>
            <div className="col-8 contentSecColumn">
                <div className="card-panel with-background cnt_table_filter_box">
                    <div className='row '>
                            {contentDough3}
                    </div>
                </div>
                <div className="card-panel with-background">
                    <div className="row cnt_box1">
                        {contentDough}
                    </div>
                </div>
            </div>
          </div>
            <QlikConnection callback={this.onMessage.bind(this)} appName="app" qvfName="de7576dd-b456-457b-9167-d18446dd86ba"/>
        </section>
        <SideBar  page="compare_period" qv_name="de7576dd-b456-457b-9167-d18446dd86ba"
            side_btn={[
              {
                text:"גאוגרפיה וגודל אוכלוסיה",link:"/share",icon:"chart-area"
              },
              {
              text:"נתח שוק ממוקד",link:"/target-share",icon:"chart-bar"
              },
              {
                text:"דשבורד מכירות",link:"/dashboard-sales"
              },
              {
               text:"השוואת תקופות",link:"/compare"
              },
              {
               text:"נתחי שוק",link:"/share-analysis"
              },
              {
               text:"salein-saleout",link:"/sales-out"
              },
              {
               text:"דירוג סניפים",link:"/branch-rating"
              },
              {
               text:"דוח דינמי",link:"/dynamic-report"
              }
               ]}
          />
      </>
    );
  }
  
}
