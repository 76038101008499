import React, { Component } from 'react';
import QlikConnection from '../qlikEngine/qlikConnection.js';
import QlikObject from '../qlikEngine/qlikObject.js';
import DoughNut from '../components/placeComponent.js'
import SideBar from "../components/sideBar.js";
import Pricesens from "./price_sens"
export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  onMessage(state){
    this.setState(state);
  }

  render() {

    var content, contentDrop, contentButton = [];
    var contentArray = [
        {  
            qlikId:"cWrEN",
            id:"cWrEN",
        },
    ]
    var contentArray2 = [
        {  
            qlikId:"b0bc2082-1215-43ce-93b2-28e66d993881",
            id:"b0bc2082-1215-43ce-93b2-28e66d993881",
            secRow:true,
            secDet:[
                {
                    num_id:1,
                    position:'col-12',
                    qlikId:'88d2d02e-69ed-4922-aa62-f90ebe87102b',
                    height:'500px',
                    id:"side_bar_filter"
                },
                {
                    num_id:3,
                    position:'col-12',
                    qlikId:'ae846285-02b3-480c-8525-a55abd3584ed',
                    height:'120px',
                    id:"ae846285-02b3-480c-8525-a55abd3584ed"
                },
                {
                    num_id:3,
                    position:'col-12',
                    qlikId:'f968ff13-9f41-4e6a-b785-4b01d882e776',
                    height:'240px',
                    id:"f968ff13-9f41-4e6a-b785-4b01d882e776"
                }
            ]
        }
    ]
    var contentDough, contentDough2, contentMap, contentTab=[];
    if(this.state.app){
    content = <QlikObject qlikId="af7521e1-e416-49a8-a1d7-37ae9022a745" chartId="helpdesk-line-chart" app={this.state.app} height="40px" minWidth="400px"/>
    contentDrop =  <QlikObject qlikId="cc29977e-2d6d-42aa-a41c-f2ab7bf1e535" chartId="helpdesk-line-chart1" app={this.state.app} height="40px" minWidth="20px"/>
    contentButton = <QlikObject qlikId="e8e54681-ae63-4dbe-b618-dbcbb69cc48f" chartId="clearButton" app={this.state.app} height="35px" minWidth="50px"/>
    contentDough = <DoughNut contents={contentArray} appKey={this.state.app} height="550px" position="col-12" cards="card"/>
    contentDough2 = <DoughNut contents={contentArray2} appKey={this.state.app} height="40px" position="col-12" cards=""/>
    }
    return (
      <>
        <section className="contentSection analysis_sec">
          <div className="row pad-2 rtl">
          <div className="col-10 contentSecColumnFixed">
                <div className="card-panel with-background row">
                  <div class="col-10">{content}</div>
                  <div class="col-2">
                  {contentButton}
                  </div>
                </div>
            </div>
            <div className="col-2 side_sec_filter contentSecColumn">
                <div className="row cnt_box1">
                    {contentDough2}
                </div>
            </div>
            <div className="col-8 contentSecColumn">
                <div className="card-panel with-background">
                    <div className="row cnt_box1">
                        {contentDough}
                    </div>
                </div>
            </div>
          </div>
            <QlikConnection callback={this.onMessage.bind(this)} appName="app" qvfName="de7576dd-b456-457b-9167-d18446dd86ba"/>
        </section>
        <SideBar dropdown_box="dFECQm" page="price_analysis" qv_name="de7576dd-b456-457b-9167-d18446dd86ba"
            side_btn={[
                {
                  text:"ניתוח מחיר לפי תקופות",link:"/price-analysis"
                },
                {
                 text:"מחשבון רגישות מחיר",link:"/price-sensitivity"
                }
            ]}
          />
      </>
    );
  }
  
}
