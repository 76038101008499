import React, { Component } from 'react';
import Header from '../components/headerComponent.js';
import QlikConnection from '../qlikEngine/qlikConnection.js';
import QlikObject from '../qlikEngine/qlikObject.js';
import DoughNut from '../components/placeComponent.js'
import SideBar from "../components/sideBar.js";
export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  onMessage(state){
    this.setState(state);
  }

  componentDidMount(){
    const rateCheck = setInterval(()=>{
      var cnt = document.querySelectorAll('.sn-kpi-glyph-wrapper svg');

      if(cnt){
        cnt.forEach((item,index,arr)=>{
          var color = item.getAttribute("data-testid");
          item.parentElement.setAttribute("data-testid",color);
          if (index === arr.length - 1){ 
            clearInterval(rateCheck); 
          }
        })
      }
    },100)
  }

  render() {

    var content, contentDrop, contentButton = [];
    var content1=[{
      qlikId:"FESQrMQ",
      id:"dough1",
      qlik2Id:"LqJ",
      id2:"text1",
  },{
    qlikId:"LktUJ",
    id:"dough2",
      qlik2Id:"AVqL",
      id2:"text2",
},{
  qlikId:"nmmEEf",
  id:"dough3",
  qlik2Id:"pbYuKUe",
  id2:"text3",
}, {
  qlikId:"GGsZBha",
  id:"dough4",
  qlik2Id:"WYWvWf",
  id2:"text4",
},
{
  qlikId:"nNzNa",
  id:"dough5",
  qlik2Id:"vpPcFzy",
  id2:"text5",
},
{
  qlikId:"AUxvsN",
  id:"dough6",
  qlik2Id:"WDPLP",
  id2:"text6",
},
{
  qlikId:"FPUPCQ",
  id:"dough7",
  qlik2Id:"sgrft",
  id2:"text7",
},
{
  qlikId:"ZXcke",
  id:"dough8",
  qlik2Id:"PLRHEJ",
  id2:"text8",
}
];
var mapContent=[{qlikId:"BHMHC", id:"map1", qlikMapMarkId:"UeuasLf", id2:"legend"}];
var tabContent=[{qlikId:'aSVqJq', id:"tab2"},{qlikId:'SvKHjN', id:"tab1"},{qlikId:"Hjgmd", id:"tab4"},{qlikId:"HCJeck", id:"tab3"}]

    var contentDough, contentMap, contentTab=[];
    if(this.state.app){
    content = <QlikObject qlikId="dc61acee-c353-40d1-af7e-44d4ab00af56" chartId="helpdesk-line-chart" app={this.state.app} height="40px" minWidth="400px"/>
    contentButton = <QlikObject qlikId="pJqWCm" chartId="clearButton" app={this.state.app} height="35px" minWidth="50px"/>
    contentDrop =  <QlikObject qlikId="cc29977e-2d6d-42aa-a41c-f2ab7bf1e535" chartId="helpdesk-line-chart1" app={this.state.app} height="40px" minWidth="20px"/>
    contentDough = <DoughNut contents={content1} appKey={this.state.app} height="100%" position="col-4" cards="card h-200" minWidth="200px"/>
    contentMap = <DoughNut contents={mapContent} appKey={this.state.app} height="100%" position="col-9" position1="col-3" cards="card h-700" minWidth="300px" Width1="180px"/>
    contentTab = <DoughNut contents={tabContent} appKey={this.state.app} height="100%" position="col-5" cards="card-panel with-background h-500" minWidth="400px" bgColor="white" bRadius="5px"/>
    }
    return (
      <>
        <section className="contentSection">
          <div className="row pad-2 rtl">
          <div className="col-10 contentSecColumnFixed">
                <div className="card-panel with-background row">
                  <div class="col-11">{content}</div>
                  <div class="col-1">
                  {contentButton}
                  </div>
                </div>
              </div>
            <div className="col-10 contentSecColumn">
                <div className="card-panel with-background">
                  <div className="row bg-white">
                    <div className="col-6">
                       <div className="row">
                          {contentMap}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row">
                          {contentDough}
                          <div className='col-4 custom_dgrm'>
                            <p className='first'><span><img src={require('../images/load_icon.png')} /></span> אחוז מכל המכירות </p>
                            <p className='arrow_icons second'><span><img src={require('../images/up_arrow.png')}/><img src={require('../images/down_arrow.png')} /></span> אחוז השינוי ביחס לפדיון הממוצע </p>
                          </div>
                        </div>
                    </div>
                    </div>
                 </div>
            </div>
          </div>
          <div className="row pad-2 chart_contnt">
            <span className='user_icn_box'>
              <img src={require('../images/user_icon.png')} />
              <img src={require('../images/user_icon.png')} />
              <img src={require('../images/user_icon.png')} />
            </span>
            {contentTab}
          </div>
            <QlikConnection callback={this.onMessage.bind(this)} appName="app" qvfName="82ccf23e-db0f-49e4-9459-b14ff3adff6d"/>
        </section>
        <SideBar dropdown_box="cc29977e-2d6d-42aa-a41c-f2ab7bf1e535" page="buyer"
          side_btn={[
            {
              text:"גאוגרפיה וגודל אוכלוסיה",link:"/share",icon:"chart-area"
            },
            {
             text:"השוואת תקופות",link:"",icon:"chart-bar"
            }
            ]}
        />
      </> 
    );
  }
  
  }


