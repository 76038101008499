import React from "react";
import loadIcon from '../images/Spinner_load3.svg'
const Loading = ()=>{
    return(
        <div className="loading_sec">
            <div>
                <img src={loadIcon}  alt="loading_icon"/>
           </div>
        </div>
    )
}

export default Loading;