import React, { Component } from 'react';
export default class QlikObject extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }
    
    componentDidMount(){
        var me = this;
        this.props.app.getObject(this.props.chartId, this.props.qlikId).then(model => {
            console.log(model.layout.qHyperCube, me.props.qlikId);
            me.setState({model:model});
            if(model){
                model.getHyperCubeData('/qHyperCubeDef', []).then(data => console.log(data))
                setTimeout(()=>{
                    document.querySelector('body').classList.add('loaded');
                },1500);
            }
        });
    }
    
    shouldComponentUpdate(nextProps, nextState){
        return this.props.qlikId !== nextProps.qlikId;
    }
    
    componentDidUpdate(){
        var me = this;
        if(this.state.model){
            this.state.model.close();
        }
        this.props.app.getObject(this.props.chartId, this.props.qlikId).then(model => me.setState({model:model}));
    }
    
    componentWillUnmount(){
        if(this.state.model){
            this.state.model.close();
        }
    }
    
    render() {
      return <div style={{"height": this.props.height,"minWidth":this.props.minWidth, "background":this.props.backGround?this.props.backGround:'', "width":this.props.Width?this.props.Width:'',  "borderRadius":this.props.bRadius?this.props.bRadius:'', }} id={this.props.chartId} />;
      }
    }