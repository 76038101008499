import React, { Component } from 'react';
import QlikConnection from '../qlikEngine/qlikConnection.js';
import QlikObject from '../qlikEngine/qlikObject.js';
import DoughNut from '../components/placeComponent.js'
import SideBar from "../components/sideBar.js";
import Pricesens from "./price_sens"
export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  onMessage(state){
    this.setState(state);
  }

  render() {

    var content, contentDrop,contentDrop2, contentButton = [];
    var contentArray = [
        {  
            qlikId:"DJKPNP",
            id:"DJKPNP",
        }
    ]
 
    var contentDough, contentMap, contentTab=[];
    if(this.state.app){
    content = <QlikObject qlikId="22409d56-6b5e-4693-9848-00d7ca16cdae" chartId="helpdesk-line-chart" app={this.state.app} height="40px" minWidth="400px"/>
    contentButton = <QlikObject qlikId="8f86e124-e72c-4b81-85b0-3d083e369efa" chartId="clearButton" app={this.state.app} height="35px" minWidth="50px"/>
    contentDough = <DoughNut contents={contentArray} appKey={this.state.app} height="520px" position="col-12" cards="card"/>
    }
    return (
      <>
        <section className="contentSection branch_rating_sec">
          <div className="row pad-2 rtl">
          <div className="col-10 contentSecColumnFixed">
                <div className="card-panel with-background row" >
                  <div class="col-7">{content}</div>
                  <div class="col-5">
                  {contentButton}
                  </div>
                </div>
            </div>
            <div className="col-10 branch_box contentSecColumn">
                <div className="card-panel with-background">
                    <div className="row cnt_box1">
                        {contentDough}
                    </div>
                </div>
            </div>
          </div>
            <QlikConnection callback={this.onMessage.bind(this)} appName="app" qvfName="de7576dd-b456-457b-9167-d18446dd86ba"/>
        </section>
        <SideBar  page="compare_period" qv_name="de7576dd-b456-457b-9167-d18446dd86ba"
            side_btn={[
              {
                text:"גאוגרפיה וגודל אוכלוסיה",link:"/share",icon:"chart-area"
              },
              {
              text:"נתח שוק ממוקד",link:"/target-share",icon:"chart-bar"
              },
              {
                text:"דשבורד מכירות",link:"/dashboard-sales"
              },
              {
               text:"השוואת תקופות",link:"/compare"
              },
              {
               text:"נתחי שוק",link:"/share-analysis"
              },
              {
               text:"salein-saleout",link:"/sales-out"
              },
              {
               text:"דירוג סניפים",link:"/branch-rating"
              },
              {
               text:"דוח דינמי",link:"/dynamic-report"
              }
               ]}
          />
      </>
    );
  }
  
}
